import { useState } from "react";
import { useTranslation } from "react-i18next";
import SlidingPane from "react-sliding-pane";
import { Loading } from "../../Components/Loading";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./Portfolio.css";
import Button from "../../Components/Button";
import Container from "../../Components/Container";
import Skeleton from "@mui/material/Skeleton";

const groupEventsByYear = (events) => {
  const grouped = new Map();

  events.forEach((event) => {
    if (!grouped.has(event.year)) {
      grouped.set(event.year, []);
    }
    grouped.get(event.year).push(event);
  });

  return Object.fromEntries(grouped);
};

export const Portfolio = () => {
  const { t } = useTranslation("common");
  const { i18n } = useTranslation();

  const groupedEvents = groupEventsByYear(portfolioEvents);
  const sortedYears = Object.keys(groupedEvents).sort((a, b) => b - a);

  const [paneVideo, setPaneVideo] = useState({
    visible: false,
    name: "",
    video: null,
    info: "",
    details: "",
  });

  const Event = (props) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const imgLink = `https://drive.google.com/thumbnail?id=${props.imgSrc}&sz=w1000`;
    return (
      <div className="event centered">
        <Button
          pd="0"
          onClick={() => {
            setPaneVideo({
              visible: true,
              name: props.videoName,
              video: (
                <iframe
                  src={props.videoLink}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              ),
              info: props.info,
            });
          }}
        >
          {!imageLoaded && (
            <Skeleton
              variant="rounded"
              width={310}
              height={180}
              sx={{ background: "rgb(120, 120, 120)" }}
              animation="wave"
            />
          )}
          <img
            src={imgLink}
            alt={props.videoName}
            style={{ display: imageLoaded ? "block" : "none" }}
            onLoad={() => setImageLoaded(true)}
          />
        </Button>
        <div className="event-info">
          {!imageLoaded ? (
            <Container column gap="0.5rem">
              <Skeleton
                variant="rounded"
                width={100}
                height={20}
                sx={{ background: "rgb(120, 120, 120)" }}
                animation="wave"
              />
              <Skeleton
                variant="rounded"
                width={80}
                height={20}
                sx={{ background: "rgb(120, 120, 120)" }}
                animation="wave"
              />
            </Container>
          ) : (
            <>
              <h5>{props.videoName}</h5>
              <p>{props.eventType}</p>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <section className="portfolio centered column">
      <SlidingPane
        overlayClassName="loading-overlay"
        isOpen={paneVideo.visible}
        onRequestClose={() => {
          setPaneVideo({ visible: false });
        }}
      >
        {paneVideo.video}
        <Container w="90%" m="2rem" gap="1rem" center column>
          <h3>{paneVideo.name}</h3>
          <h5 style={{ textAlign: "justify" }}>{paneVideo.info}</h5>
        </Container>
      </SlidingPane>

      {sortedYears.map((year) => (
        <Container
          w="90%"
          column
          gap="1rem"
          m="2rem 0 0 0"
          key={year}
          id={year}
        >
          <div className="year">
            <h3>{year}</h3>
          </div>
          <div className="event-container">
            {groupedEvents[year].map((event, index) => (
              <Event
                key={index}
                videoName={event.videoName}
                eventType={event.eventType}
                eventDate={event.eventDate}
                imgSrc={event.imgSrc}
                info={
                  i18n.language === "en"
                    ? event.info
                    : i18n.language === "ru"
                    ? event.infoRu
                    : event.infoAz
                }
                videoLink={event.videoLink}
              />
            ))}
          </div>
        </Container>
      ))}
    </section>
  );
};

const portfolioEvents = [
  {
    eventType: "Prom Night",
    imgSrc: "1ozdCKq8jVMLWexKAiRTrzNGrVtRtfQIe",
    info: "Fiesta had the honor of hosting and organizing an unforgettable Prom night for the graduates of School No. 46 at Shani Hotel & Villas. The event, beautifully captured on video, showcased our dedication as the primary coordinators of this memorable evening. In line with the client’s vision, the night featured DJ performances and a cake-cutting ceremony, adding to the celebration's vibrant atmosphere. It was a special occasion for the students to unwind, dance the night away, and temporarily escape their academic concerns. The entire event, from the meticulous decorations to the energetic DJ sets, was flawlessly executed by Fiesta Event Management Company.",
    videoLink: "https://www.youtube.com/embed/1QRdytXtiuI?si=RyY_h6tAeY0-3ZwL",
    videoName: "46 School",
    year: 2023,
  },
  {
    eventType: "Final Party",
    imgSrc: "1SZ1eEguVv72PjmmGz-DFKgiAxG5VsJUA",
    info: "Another flawlessly organized event provided Fiesta with yet another opportunity to build a satisfied and engaged customer base, who continue to speak fondly of that memorable night. Fiesta is renowned for its expertly planned events for the ADA community and proudly serves this group year after year. The ADA School’s final party, held at Dreamland Event hall, was no exception. Fiesta Event Management curated a uniquely designed evening for the school’s vibrant teenagers. As with all our projects, we placed great importance on respecting our clients’ preferences and customizing the event to meet their specific needs. Throughout the night, our stylish young attendees danced with enthusiasm, celebrating the end of the semester to the tunes of our DJ, without any concerns about the event's technical details—thanks to Fiesta's seamless execution.",
    videoLink: "https://www.youtube.com/embed/T3NkcpTznNI?si=FpHMHL5yE_dLmxKX",
    videoName: "ADA School",
    year: 2023,
  },
  {
    eventType: "Prom Night",
    imgSrc: "1wJuaUwwmGWxWDUFoy0QlXXJ5WaMO_uMp",
    info: "The year 2022 will forever be remembered for this remarkable gala night, a celebration that captured widespread local attention and continues to be recalled with fond, unforgettable memories. The ADA School Graduation Party 2022, hosted at the prestigious Buta Palace, became the most luxurious PROM of the year. The evening was filled with boundless energy, from the dazzling performances by the girls to the charismatic showcases by the boys, all brought to life with the invaluable support of Fiesta. The event reached new heights of extravagance with electrifying performances by DJ Kenan Drums and Murad Arif, and a show-stopping performance by renowned singer Miri Yusif. The night culminated in a breathtaking fireworks display, securing its place as the premier high-quality, luxury event of the year.",
    videoLink: "https://player.vimeo.com/video/862658981?h=32c3293651",
    videoName: "ADA School",
    year: 2022,
  },
  {
    eventType: "Prom Night",
    imgSrc: "14K7lAEphebXbjXH4SN4wavVXzmVzfHEt",
    info: "Fiesta wouldn't truly live up to its name without hosting its renowned city-wide PROM nights! We are thrilled to have orchestrated yet another flawlessly tailored successful event, this time for ADA School graduates, ensuring that their most cherished moments were captured as they envisioned. On this enchanting night, when each graduate felt like the main character of a fairy tale, Fiesta transformed Eluxe Events Hall into a grand celebration. The evening featured performances by popular artists such as Nigar Jamal, the all-time classic Eyyub Yagubov, and Murad Arif, who masterfully led the night's festivities. This long-awaited event, entrusted entirely to Fiesta, allowed the students to simply fulfill their only task: dancing until the break of dawn at this once-in-a-lifetime celebration!",
    videoLink: "https://www.youtube.com/embed/OY9mkxeFT2U?si=uhtdBv67_bSWfKPJ",
    videoName: "ADA School",
    year: 2023,
  },
  {
    eventType: "Met Gala",
    imgSrc: "1a_HwaQD2SmWIDp0hhI--rlksh5J2v2St",
    info: "The ADA Met Gala'2023 is one of the most memorable events that Fiesta Events has ever hosted. This is because it is the very first Gala Night that the organization has ever attempted to host. It was made even more starry and gorgeous by the presence of Eyyub Yagubov, Zamig Huseynov, and Samra Eurovision, all of whom are exceptional star singers. To have a fantastic time and make lovely memories with one another, members of the Young Generation got together to spend time together.",
    infoRu: "",
    videoLink: "https://player.vimeo.com/video/862668571?h=c79bff65f6",
    videoName: "ADA University",
    year: 2022,
  },
  {
    eventType: "New Year Party",
    imgSrc: "1lzwVm40T2fU6a2DmVekebxW6lo27596w",
    info: "In addition to Prom nights, New Year's celebrations are another highly anticipated occasion, offering the perfect opportunity to dress elegantly, indulge in glamour, dance, laugh, and momentarily escape the stresses of teenage life. Fiesta excels in organizing such events! The New Year’s celebration at Elektra Events Hall, reserved and specially designed for ADA School students, was nothing short of magical. The night featured captivating lighting performances, flame shows, unique activities to engage all attendees, dynamic drum performances, and a cake-cutting ceremony. Judging by the vibrant atmosphere captured in the scenes, we are proud to say we successfully created a dazzling night where happiness radiated from every guest!",
    videoLink: "https://www.youtube.com/embed/_P-94KWeslo?si=O5wgIaxnAWm3lFAM",
    videoName: "ADA School",
    year: 2023,
  },
  {
    eventType: "New Year Party",
    imgSrc: "1jIunomvon-1NQibrf0O3hjKczpeBv_ci",
    info: "CELT Colleges New Year's party is an offer of CELT Colleges, one of the prestigious educational centers of Baku. In addition, this educational facility provides a variety of programs, as well as courses and schools. The college made the decision to gather together for the party at the Fairmont Hotel, which is considered to be one of the most renowned hotels in the central business district. It was the goal of the community to have a good time and to make memories that will last a lifetime. In addition, one of the most fascinating aspects was the presence of distinguished guests such as Eyyub Yagubov and Fahree, a representative from Azerbaijan for Eurovision 2024.",
    videoLink: "https://www.youtube.com/embed/pymezSzn4Qg?si=NIMC0UWEBCrbC8Wg",
    videoName: "CELT Colleges",
    year: 2023,
  },
  {
    eventType: "Theme Party",
    imgSrc: "1mho1txeRAnhtoqjzelYdNk7yg-Rvq7G9",
    info: "What about conceptual nights in the middle of the semester? These events offer yet another perfect opportunity to unwind, immerse in the atmosphere, and experience an evening where every guest embraces the theme with imaginative costumes, concept-driven performances, and dazzling laser shows. Once again, Fiesta delivered a night meticulously designed for maximum enjoyment! For this particular occasion, Unbar was chosen as the ideal venue to capture the true essence of Halloween. Our guests, showing remarkable creativity and imagination, arrived in costumes perfectly aligned with the theme, enhancing the spooky ambiance we aimed to create throughout the night. The success of the event is unmistakable—watching the short video, it’s clear that each guest transformed into a unique Halloween character, relishing every moment until the very end!",
    videoLink: "https://www.youtube.com/embed/6J9Vps_rGZ0?si=dIVAY0Na12kkBCpM",
    videoName: "Halloween Night",
    year: 2023,
  },
  {
    eventType: "New Year Party",
    imgSrc: "1gTdwb7HtUGnd66StLg7QH_D2Qdw0kxBc",
    info: "Embark on a nostalgic journey to the enchanting New Year Starry Night! ✨ Fiesta Events promises to weave magic into the night, taking you on a captivating adventure behind the scenes. Join our magnificent host, Elmeddin Mammadov, as he guides you through the wonderland of festivities, joined by special guests Roya Official and Zamig Huseynov. Feel the energy surge as DJ AX sets the stage on fire with electrifying beats!",
    videoLink: "https://www.youtube.com/embed/x-yzu_oxd3c?si=um2gh0YFWL_AeIzR",
    videoName: "Starry Night",
    year: 2023,
  },
  {
    eventType: "Theme Party",
    imgSrc: "1XWvfDKVxL3umwalJSRg7jIsE7VzBa-5Y",
    info: "The ADA Murder Party is an additional level of the event, which brings together the ideas of the party and the game in order to produce a unique environment in which the winners of the event game can take pleasure in their experience and emerge victorious. It is the special offer, and during the event, special additional game details were included in the package containing the offer.",
    videoLink: "https://www.youtube.com/embed/D7MZ_WNloRY?si=lalkUoWl2K8LbRL8",
    videoName: "Summer Murder Mystery",
    year: 2023,
  },
];
