import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './NavLinks.css';



import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import Container from '../../../Container';




export const NavLinks = ({ className, toggleMenu, userRoles }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (path) => {
    if (toggleMenu) toggleMenu();
    navigate(path);
  };

  const handleScannerMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'scanner-popover' : undefined;

  return (
    <div className={`${className} nav-links`}>
      <p onClick={() => handleClick('/')}>{t('navbar.home')}</p>
      <p onClick={() => handleClick('/about')}>{t('navbar.aboutUs')}</p>
      <p onClick={() => handleClick('/contacts')}>{t('navbar.contactUs')}</p>
      <p onClick={() => handleClick('/portfolio')}>{t('navbar.portfolio')}</p>
      <p onClick={() => handleClick('/events')}>{t('navbar.events')}</p>
      <p onClick={() => handleClick('/feedback')}>{t('navbar.feedback')}</p>

      <Container gap='0.3rem'>
        {(userRoles.isAdmin || userRoles.isReception || userRoles.isVolunteer) && (
          <div>
            <IconButton
              aria-describedby={id}
              onClick={handleScannerMenu}
              style={{ color: 'rgb(112, 209, 254)' }}
            >
              <QrCodeScannerIcon />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Container column pd='0 0.5rem' bg='rgb(0, 23, 54)' className='scanner-options'>
                {(userRoles.isAdmin || userRoles.isReception) && (
                  <p onClick={() => { handleClick(`/${process.env.REACT_APP_RECEPTION_LINK}`); handleClose(); }}>
                    {t('navbar.reception')}
                  </p>
                )}
                {(userRoles.isAdmin || userRoles.volunteerGroup.includes('1')) && (
                  <p onClick={() => { handleClick(`/${process.env.REACT_APP_SCANNER1_LINK}`); handleClose(); }}>
                    Scanner1
                  </p>
                )}
                {(userRoles.isAdmin || userRoles.volunteerGroup.includes('2')) && (
                  <p onClick={() => { handleClick(`/${process.env.REACT_APP_SCANNER2_LINK}`); handleClose(); }}>
                    Scanner2
                  </p>
                )}
                {(userRoles.isAdmin || userRoles.volunteerGroup.includes('3')) && (
                  <p onClick={() => { handleClick(`/${process.env.REACT_APP_SCANNER3_LINK}`); handleClose(); }}>
                    Scanner3
                  </p>
                )}
              </Container>
            </Popover>
          </div>
        )}

        {userRoles.isAdmin && 
          <IconButton
            onClick={() => handleClick(`/${process.env.REACT_APP_ADMIN_LINK}`)}
            style={{ color: 'rgb(112, 209, 254)' }}
          >
            <AdminPanelSettingsIcon aria-label='Admin Panel'/>
          </IconButton>
        }

        {userRoles.isTeam && 
          <IconButton 
            onClick={() => handleClick(`/${process.env.REACT_APP_TEAM_LINK}`)}
            style={{ color: 'rgb(112, 209, 254)' }}
          >
            <Diversity1Icon/>
          </IconButton>
        }
      </Container>
    </div>
  );
};

