import "./Admin.css";
import { useState, useMemo, useEffect, useCallback } from "react";
import { Loading } from "../../Components/Loading";
import { useNavigate } from "react-router-dom";
import useFirestoreCollection from "../../hooks/useFirestoreCollection";
import Button from "../../Components/Button";
import Select from "../../Components/Select";
import Container from "../../Components/Container";
import CustomTable from "../../Components/CustomTable";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export const Report = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState("");
  const [reloadTrigger, setReloadTrigger] = useState(0);

  const events = useFirestoreCollection("events", setIsLoading);
  const addedUsers = useFirestoreCollection(
    selectedCollection,
    setIsLoading,
    reloadTrigger
  );

  const collections = useMemo(
    () =>
      events
        .filter((event) => event.isReport)
        .map((event) => ({
          name: event.name,
          value: event.value,
        })),
    [events]
  );

  useEffect(() => {
    if (collections.length > 0 && !selectedCollection) {
      setSelectedCollection(collections[0].value);
    }
  }, [collections, selectedCollection]);

  const handleCollectionChange = (event) => {
    const selected = collections.find((c) => c.value === event.target.value);
    setSelectedCollection(selected.value);
  };

  const handleReload = useCallback(() => {
    setReloadTrigger((prev) => prev + 1);
  }, []);

  return (
    <section className="users-list centered column">
      <Button
        variant="ordinary"
        m="1rem"
        pd="0.5rem"
        onClick={() => navigate("/" + process.env.REACT_APP_ADMIN_LINK)}
        sx={{ position: "absolute", top: "0", left: "0" }}
      >
        <ArrowBackIosNewIcon fontSize="small" />
      </Button>

      <Container sx={{ position: "absolute", top: "1rem", right: "1rem" }}>
        <h5>Report</h5>
      </Container>

      <Container w="100%" m="2rem 0 0 0" center>
        <Select
          onChange={handleCollectionChange}
          value={selectedCollection}
          fontSize="medium"
          options={collections.map((collection) => ({
            value: collection.value,
            label: collection.name,
          }))}
        />
      </Container>

      <CustomTable
        columns={[
          { id: "name", label: "Name" },
          { id: "surname", label: "Surname" },
          { id: "email", label: "Email" },
          { id: "phone", label: "Phone" },
          { id: "table_num", label: "Table" },
        ]}
        rows={addedUsers}
        status='marked'
        handleReload={handleReload}
      />

      {isLoading && <Loading />}
    </section>
  );
};
