import useFirestoreCollection from "../../hooks/useFirestoreCollection";
import { useState, useRef } from "react";
import { Loading } from "../../Components/Loading";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./Admin.css";
import Container from "../../Components/Container";
import Button from "../../Components/Button";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export const UsersEmail = () => {
  const navigate = useNavigate();
  const emailsRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [current, setCurrent] = useState([]);
  const users = useFirestoreCollection("users", setIsLoading);
  const adaUniUsers = useFirestoreCollection("ADA_Uni_Prom", setIsLoading);

  return (
    <section className="users-list centered column">
      <Button
        variant="ordinary"
        m="1rem"
        pd="0.5rem"
        onClick={() => navigate("/" + process.env.REACT_APP_ADMIN_LINK)}
        sx={{ position: "absolute", top: "0", left: "0" }}
      >
        <ArrowBackIosNewIcon fontSize="small" />
      </Button>
      <Container w="100%" m="2rem 0 0 0" center>
        <h3>Emails</h3>
      </Container>
      <Container w="90%" gap="0.5rem">
        <Button variant="ordinary" onClick={() => setCurrent(users)}>
          All users
        </Button>
        <Button variant="ordinary" onClick={() => setCurrent(adaUniUsers)}>
          ADA Uni Prom
        </Button>
      </Container>
      <Container
        w="90%"
        gap="0.5rem"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <p style={{ margin: 0 }}>Number of users: {current.length}</p>
        <Button
          variant="ordinary"
          onClick={() => {
            navigator.clipboard.writeText(emailsRef.current.textContent);
            toast.success("Emails Copied!");
          }}
        >
          Copy Emails
        </Button>
      </Container>
      <div className="users" ref={emailsRef}>
        {current.map((user) => (
          <div
            key={user.id}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <p style={{ color: "white", marginRight: "10px" }}>{user.id}</p>
          </div>
        ))}
      </div>
      {isLoading && <Loading />}
    </section>
  );
};
