import "./Events.css";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { Loading } from "../../Components/Loading";
import { toast } from "react-toastify";
import { auth, db } from "../../fb";
import { doc, setDoc } from "firebase/firestore";
import { EventPayment } from "../../forms/EventPayment";
import { TableReservation } from "../../forms/TableReservation";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../../Components/Button";
import Container from "../../Components/Container";
import Skeleton from "@mui/material/Skeleton";

export const Events = () => {
  const { t } = useTranslation("common");
  const { i18n } = useTranslation();

  const passwordRef = useRef("");
  const [password, setPassword] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [school, setSchool] = useState("");
  const [section, setSection] = useState("");

  const [formType, setFormType] = useState("payment");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [paneVideo, setPaneVideo] = useState({
    visible: false,
    name: "",
    video: null,
    info: "",
    details: "",
  });

  const Event = (props) => {
    const imgLink = `https://drive.google.com/thumbnail?id=${props.imgSrc}&sz=w1000`;
    const [imageLoaded, setImageLoaded] = useState(false);
    return (
      <div className="event centered">
        <Button
          pd="0"
          onClick={() => {
            setPaneVideo({
              visible: true,
              name: props.eventName,
              video: <img src={imgLink} className="pane-img" />,
              info: props.info,
              collection: props.collection,
              payment: props.payment,
              paymentTicketOptions: props.paymentTicketOptions,
              reservation: props.reservation,
              reservationTicketOptions: props.reservationTicketOptions
            });
          }}
        >
          {!imageLoaded && (
            <Skeleton
              variant="rounded"
              width={310}
              height={180}
              sx={{ background: "rgb(120, 120, 120)" }}
              animation="wave"
            />
          )}
          <img
            src={imgLink}
            style={{ display: imageLoaded ? "block" : "none" }}
            onLoad={() => setImageLoaded(true)}
          />
        </Button>

        <div className="event-info">
          {!imageLoaded ? (
            <Container column gap="0.5rem">
              <Skeleton
                variant="rounded"
                width={100}
                height={20}
                sx={{ background: "rgb(120, 120, 120)" }}
                animation="wave"
              />
              <Skeleton
                variant="rounded"
                width={80}
                height={20}
                sx={{ background: "rgb(120, 120, 120)" }}
                animation="wave"
              />
            </Container>
          ) : (
            <>
              <h5>{props.eventName}</h5>
              <p>{props.eventType}</p>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <section className="events centered">
      <SlidingPane
        overlayClassName="loading-overlay"
        isOpen={paneVideo.visible}
        onRequestClose={() => {
          setPaneVideo({ visible: false });
        }}
      >
        {paneVideo.video}

        <Container w="90%" m="1rem 0 2rem 0" center column gap="1rem">
          <h3>{paneVideo.name}</h3>
          {paneVideo.info}
        </Container>

        {(paneVideo.reservation || paneVideo.payment) && (
          <Container
            center
            w="80%"
            gap="1rem"
            m="0 0 2rem 0"
            className="row-column"
            sx={{ justifyContent: "space-evenly" }}
          >
            {paneVideo.reservation && (
              <Button
                variant="modern"
                shadow
                pd="2rem 0"
                w="100%"
                onClick={() => {
                  handleOpen();
                  setFormType("reservation");
                }}
                children={
                  <Container center w="100%" column color="rgb(0, 14, 44)">
                    <h4>
                      <strong>Reserve Table</strong>
                    </h4>
                  </Container>
                }
              />
            )}
            {paneVideo.payment && (
              <Button
                variant="modern"
                shadow
                pd="2rem 0"
                w="100%"
                onClick={() => {
                  handleOpen();
                  setFormType("payment");
                }}
                children={
                  <Container center w="100%" column color="rgb(0, 14, 44)">
                    <h4>
                      <strong>Upload Payment</strong>
                    </h4>
                  </Container>
                }
              />
            )}
          </Container>
        )}

        <Modal open={open} onClose={handleClose} sx={{ overflow: "scroll" }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "white",
              minHeight: "100%",
              overflow: "scroll",
              position: "relative",
            }}
            className="centered"
          >
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
            >
              <CloseIcon />
            </IconButton>

            {formType === "payment" && (
              <EventPayment
                collection={paneVideo.collection? paneVideo.collection : ""}
                ticketOptions={paneVideo.paymentTicketOptions}
              />
            )}

            {formType === "reservation" && (
              <TableReservation
                ticketOptions={paneVideo.reservationTicketOptions}
              />
            )}
          </div>
        </Modal>

      </SlidingPane>

      <Container w="90%" column gap="1rem" m="2rem 0 0 0">
        <div className="event-container">
          {eventsEvents.map((event, index) => (
            <Event
              key={index}
              eventName={event.eventName}
              eventType={event.eventType}
              collection={event.collection}
              payment={event.payment}
              paymentTicketOptions={event.paymentTicketOptions}
              reservation={event.reservation}
              reservationTicketOptions={event.reservationTicketOptions}
              imgSrc={event.imgSrc}
              info={
                i18n.language === "en"
                  ? event.info
                  : i18n.language === "ru"
                  ? event.infoRu
                  : i18n.language === "az"
                  ? event.infoAz 
                  : ''
              }
              videoLink={event.videoLink}
            />
          ))}
        </div>
      </Container>
      

      <Container w="100%" center m="3rem 0">
        <h1>
          {t("events.more")}
          <br />
          {t("events.dont-miss")}
        </h1>
      </Container>

      {isLoading && <Loading />}
    </section>
  );
};

const eventsEvents = [
  {
    eventName: (
      <span>
        <span style={{ textDecoration: "line-through" }}>Black</span> Bloody
        Jack
      </span>
    ),
    eventType: "Theme Party",
    imgSrc: "1EIh-kz4ybk4pe6YnwCEMRPy90HG0uJka",
    info: (
        <p>Halloween is creeping up, and we’re thrilled to invite you to one of our most spine-chilling events of the year.
        <br/>October 27th is your night to enter our haunted Las Vegas casino at Jolly Joker (Elektra Events Hall), where glamour meets the ghostly in a spectacular Halloween celebration!
        <br/>
        <br/>🎃 <strong>EVENT DETAILS</strong> 🎃
        <br/>🗓️ <strong>Date & Time:</strong> 27 October, 18:00
        <br/>📍 <strong>Location:</strong> Jolly Joker (Elektra Events Hall )
        <br/>🎟️ <strong>Ticket Types:</strong> Standard Zone, Premium Zone, VIP Zone
        <br/>
        <br/><strong>Standard Zone - 60 AZN</strong>
        <br/>&nbsp;• Standing Position (Separated Zone)
        <br/>&nbsp;• Access to the standard zone <strong>ONLY</strong>
        <br/>&nbsp;• Access to the Buffet
        <br/>&nbsp;• Special Led Bracelet
        <br/>&nbsp;• Opportunity to participate in a poll
        <br/>&nbsp;• Take special memories in Photobooth
        <br/>&nbsp;• Face art and special 2 Interactive Games
        <br/>&nbsp;• Special DJ AX Music Ambiance and Special Show Program
        <br/>&nbsp;• Special Dance Performance by MadHuskies
        <br/>
        <br/><strong>Premium Zone - 100 AZN</strong>
        <br/>&nbsp;• Standard ticket privileges
        <br/>&nbsp;• Seating Position (1st Floor)
        <br/>&nbsp;• Access to the premium zones
        <br/>&nbsp;• Special menu of food & beverage
        <br/>&nbsp;• 2 kind of Alcoholic Drinks;
        <br/>&nbsp;• Additional 3 Interactive Games
        <br/>&nbsp;• Special Contest of the best costume of the event
        <br/>&nbsp;• Special PhotoZone
        <br/>
        <br/><strong>VIP Zone - 130 AZN</strong>
        <br/>&nbsp;• Standard and Premium ticket privileges
        <br/>&nbsp;• Seating Position (2nd floor)
        <br/>&nbsp;• VIP <strong>Parking Spot</strong>
        <br/>&nbsp;• Special <strong>Separate</strong> Entrance
        <br/>&nbsp;• Access to VIP zone
        <br/>&nbsp;• 3 kinds of Alcoholic Drinks
        <br/>&nbsp;• Additional Private Teams Games
        <br/>&nbsp;• Special Prize Auction of the Day (Opportunity to win something special)
        <br/>
        <br/><strong>For More Info and Tickets:</strong>
        <br/>📞 +994107137007
        <br/>📨 fiestagroup@ada.edu.az
        <br/>
        <br/>We encourage you to unleash your creativity and come dressed in your most glamorous yet ghoulish Halloween attire!
        <br/>🕸 Don’t miss out on a thrilling evening of high stakes, high spirits, and hauntingly good times. This is your chance to celebrate Halloween in style—with a perfect mix of spooky sophistication and casino fun! 🎰💀
        </p>
    ),
    videoLink: "https://www.youtube.com/embed/1QRdytXtiuI?si=RyY_h6tAeY0-3ZwL",
    collection: "Black_Bloody_Jack",
    payment: true,
    paymentTicketOptions: [
        { value: "STANDARD", label: "Standard Zone - 60 AZN" },
        { value: "PREMIUM", label: "Premium Zone - 100 AZN" },
        { value: "VIP", label: "VIP Zone - 130 AZN" },
    ],
    reservation: true,
    reservationTicketOptions: [
        // { value: "STANDARD", label: "Standard Zone - 60 AZN" },
        { value: "PREMIUM", label: "Premium Zone - 100 AZN" },
        // { value: "VIP", label: "VIP Zone - 130 AZN" },
    ],
  },
  //   {
  //     eventName: <span>Co-Founder of Baku Fashion Week</span>,
  //     eventType: "Fiesta Talks",
  //     imgSrc: "12DjCz5lrtvtHsYvvZTOIerwxlDmsmFgH",
  //     info: (
  //       <p>
  //         We are excited to announce the launch of Fiesta Talks: Event Edition, an
  //         exclusive two-month program that aims to provide ADA University
  //         students, faculty, and staff with unparalleled access to professionals
  //         in the event management industry. This program will offer weekly
  //         information sessions featuring renowned companies from Azerbaijan,
  //         sharing their experiences, insights, and best practices in organizing
  //         exceptional events.
  //         <br />
  //         <br />
  //         Our first session, in collaboration with ADALI Magazine, will take place
  //         on October 10th, 2024. We are thrilled to welcome Baku Fashion Week
  //         Events Group, with Katerina Eren and her team as the featured speakers.
  //         They will share their expertise on organizing the prestigious Baku
  //         Fashion Week, giving you a behind-the-scenes look at how such a
  //         large-scale event comes to life.
  //         <br />
  //         <br />
  //         <strong>EVENT DETAILS</strong>
  //         <br />
  //         🗓️ <strong>Date & Time:</strong> 10 October, 17:00
  //         <br />
  //         📍 <strong>Venue:</strong> ADA University, C Large Auditorium
  //         <br />
  //         <br />
  //         Participants who attend will have the opportunity to receive
  //         certificates upon completing the program. Please note that you are
  //         allowed a maximum of two excused absences throughout the program. Full
  //         participation is required to qualify for certification.
  //       </p>
  //     ),
  //     videoLink: "https://www.youtube.com/embed/1QRdytXtiuI?si=RyY_h6tAeY0-3ZwL",
  //     collection: "Black Bloody Jack",
  //     payment: false,
  //     reservation: false,
  //   },
];
