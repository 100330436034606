import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";
import Container from "../../../Components/Container";

export const Services = () => {
  const { t } = useTranslation("common");

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 950 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 950, min: 550 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1,
    },
  };

  return (
    <Container
      id='services'
      w="100%"
      pd="3rem 0"
      center
      bg="linear-gradient(to bottom, black, rgb(0, 44, 74))"
      className="our-services"
    >
      <Container w="90%" sx={{ display: "block" }}>
        <h1>{t("home.services.header")}</h1>
        <h5 style={{ color: "rgb(65, 189, 255)" }}>
          {t("home.services.subHeader")}
        </h5>

        <Carousel
          centerMode={true}
          responsive={responsive}
          ssr={true}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={2500}
        >
          {services.map((service, index) => {
            const imageUrl = `/images/our-services/${service.imgUrl}.jpg`;
            return (
              <Container key={index} column m="1rem 0.5rem 0 0.5rem">
                <img
                  src={imageUrl}
                  alt={service.title}
                  style={{
                    width: "100%",
                    aspectRatio: 3 / 4,
                    objectFit: "cover",
                  }}
                />
                <h4 style={{ color: "rgb(65, 189, 255)" }}>
                  {t(service.title)}
                </h4>
                <p>"{t(service.description)}"</p>
              </Container>
            );
          })}
        </Carousel>
      </Container>
    </Container>
  );
};

const services = [
  {
    imgUrl: "prom-nights",
    title: "Prom Nights",
    description: "home.services.service1-text",
  },
  {
    imgUrl: "parties",
    title: "Parties",
    description: "home.services.service2-text",
  },
  {
    imgUrl: "gala-nights",
    title: "Gala Nights",
    description: "home.services.service3-text",
  },
  {
    imgUrl: "trips",
    title: "Trips",
    description: "home.services.service4-text",
  },
  {
    imgUrl: "movie-nights",
    title: "Movie Nights",
    description: "home.services.service5-text",
  },
  {
    imgUrl: "graduation-ceremonies",
    title: "Graduation Ceremonies",
    description: "home.services.service6-text",
  },
  {
    imgUrl: "events",
    title: "Events",
    description: "home.services.service7-text",
  },
];
