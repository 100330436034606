import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useForm from "../hooks/useForm";
import { setDocument } from "../fb/FirebaseFunctions";
import { Loading } from "../Components/Loading";
import Container from "../Components/Container";
import Button from "../Components/Button";
import Select from "../Components/Select";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import PhoneIcon from "@mui/icons-material/Phone";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { IMaskInput } from "react-imask";
import CountryCodeSelector from "../Components/CountryCodeSelector";
import useCurrentUser from "../hooks/useCurrentUser";
import CloseIcon from "@mui/icons-material/Close";

const validateReservation = (formData, userData, phoneMask, t) => {
  const { name, surname, phone, table_type, tableMates } = formData;

  if (!userData) {
    if (
      /\d/.test(name) ||
      /[^a-zA-Z0-9\s]/.test(name) ||
      name.length < 2
    ) {
      toast.error(t("error.invalidName"));
      return false;
    }
    if (
      /\d/.test(surname) ||
      /[^a-zA-Z0-9\s]/.test(surname) ||
      surname.length < 2
    ) {
      toast.error(t("error.invalidSurname"));
      return false;
    }

    const cleanedPhone = phone.replace(/\D/g, "");
    const cleanedMask = phoneMask.replace(/\D/g, "");
    if (cleanedPhone.length !== cleanedMask.length) {
      toast.error(t("error.invalidPhone"));
      return false;
    }
  }

  if (!table_type) {
    toast.error(t("error.tableTypeRequired"));
    return;
  }

  for (let mate of tableMates) {
    if (
      /\d/.test(mate.name) ||
      /[^a-zA-Z0-9\s]/.test(mate.name) ||
      !/^\s*\S+\s*$/.test(mate.name) ||
      mate.name.length < 2
    ) {
      toast.error(t("error.invalidName"));
      return false;
    }
    if (
      /\d/.test(mate.surname) ||
      /[^a-zA-Z0-9\s]/.test(mate.surname) ||
      !/^\s*\S+\s*$/.test(mate.surname) ||
      mate.surname.length < 2
    ) {
      toast.error(t("error.invalidSurname"));
      return false;
    }
  }

  return true;
};

const PhoneMask = React.forwardRef(function PhoneMask(props, ref) {
  const { onChange, mask, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={mask}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

export const TableReservation = ({ ticketOptions }) => {
  const { t } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const [userData] = useCurrentUser();

  const [phoneMask, setPhoneMask] = useState("00 000 00 00");

  const initialState = {
    name: "",
    surname: "",
    phone: "",
    countryCode: "+994",
    table_type: "",
    tableMates: [
      { name: "", surname: "" },
      { name: "", surname: "" },
    ],
  };

  const [formData, handleChange, updateFormData] = useForm(initialState);

  const handleAddTableMate = () => {
    if (formData.tableMates.length < 9) {
      updateFormData({
        ...formData,
        tableMates: [...formData.tableMates, { name: "", surname: "" }],
      });
    } else {
      toast.error(t("error.maxMates"));
    }
  };

  const handleRemoveTableMate = (index) => {
    const updatedMates = [...formData.tableMates];
    updatedMates.splice(index, 1);
    updateFormData({ ...formData, tableMates: updatedMates });
  };

  const handleTableMateChange = (index, event) => {
    const updatedMates = [...formData.tableMates];
    updatedMates[index][event.target.name] = event.target.value;
    updateFormData({ ...formData, tableMates: updatedMates });
  };

  const handleCountryCodeChange = (event) => {
    const selectedCode = event.target.value;
    setPhoneMask("00 000 00 00");
    updateFormData({
      countryCode: selectedCode,
      phone: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateReservation(formData, userData, phoneMask, t)) {
      return;
    }

    try {
      const today = new Date();
      const currentTime = today.getTime();

      const reversedTimestamp = (9999999999999 - currentTime).toString();
      const dateTime = `${today.toDateString()} ${today.toLocaleTimeString()}`;

      const matesString = formData.tableMates
        .map((mate) => `${mate.name} ${mate.surname}`)
        .join(" --- ");

      const reservationData = {
        name: userData ? userData.name : formData.name,
        surname: userData ? userData.surname : formData.surname,
        phone: userData
          ? userData.phone
          : `(${formData.countryCode})${formData.phone}`,
        tableMates: matesString,
        table_type: formData.table_type,
        timestamp: dateTime,
      };

      await setDocument(
        "reservations",
        reversedTimestamp,
        reservationData,
        setIsLoading
      );

      toast.success(t("reservation.success"));
      updateFormData(initialState);
    } catch (error) {
      toast.error(t("reservation.error"));
      console.error("Error:", error.message);
    }
  };

  return (
    <Container center w="100%" gap="1rem" m="3rem 0" column>
      <Container
        center
        column
        shadow
        rounded
        w="clamp(16.5rem, 30rem, 85%)"
        pd="2rem 0"
        gap="1rem"
        bg="white"
      >
        <h3 style={{ color: "black" }}>{t("reservation.header")}</h3>

        <form onSubmit={handleSubmit} className="centered w100">
          <Container center column w="85%" gap="1rem">
            {!userData && (
              <>
                <Container end w="100%">
                  <TextField
                    name="name"
                    label={t("general.name")}
                    variant="standard"
                    value={formData.name}
                    onChange={handleChange}
                    style={{ marginRight: "0.5rem", width: "100%" }}
                  />
                  <TextField
                    name="surname"
                    label={t("general.surname")}
                    variant="standard"
                    value={formData.surname}
                    onChange={handleChange}
                    style={{ width: "100%" }}
                  />
                  <AccountCircle className="icon" sx={{ color: "black" }} />
                </Container>

                <Container end w="100%">
                  <CountryCodeSelector
                    value={formData.countryCode}
                    onChange={handleCountryCodeChange}
                    onMaskChange={setPhoneMask}
                  />
                  <FormControl variant="standard" style={{ width: "100%" }}>
                    <InputLabel htmlFor="phoneNumber">
                      {t("general.phone")}
                    </InputLabel>
                    <Input
                      value={formData.phone}
                      onChange={handleChange}
                      name="phone"
                      id="phoneNumber"
                      inputComponent={PhoneMask}
                      inputProps={{
                        mask: phoneMask,
                      }}
                    />
                  </FormControl>
                  <PhoneIcon className="icon" sx={{ color: "black" }} />
                </Container>
              </>
            )}

            {ticketOptions && (
              <Select
                name="table_type"
                value={formData.table_type}
                onChange={handleChange}
                variant="ordinary"
                w="100%"
                placeholder={t("reservation.selectTable")}
                options={ticketOptions}
              />
            )}

            <Container color="black" m="1rem 0 0 0" w="100%">
              <strong>Add Table Mates (minimum 2):</strong>
            </Container>

            {formData.tableMates.map((mate, index) => (
              <Container key={index} end w="100%" gap="0.5rem">
                <TextField
                  name="name"
                  label={t("general.name")}
                  variant="standard"
                  value={mate.name}
                  onChange={(e) => handleTableMateChange(index, e)}
                  style={{ marginRight: "0.5rem", width: "100%" }}
                />
                <TextField
                  name="surname"
                  label={t("general.surname")}
                  variant="standard"
                  value={mate.surname}
                  onChange={(e) => handleTableMateChange(index, e)}
                  style={{ width: "100%" }}
                />
                {index >= 2 && (
                  <Button
                    variant="error"
                    pd="0.2rem"
                    onClick={() => handleRemoveTableMate(index)}
                  >
                    <CloseIcon />
                  </Button>
                )}
              </Container>
            ))}

            <Container w="100%" end>
              <Button onClick={handleAddTableMate}>
                {t("general.add")}
              </Button>
            </Container>

            <Button w="100%" type="submit">
              <p>{t("reservation.submitBtn")}</p>
            </Button>
          </Container>
        </form>
        <Container color="gray">
          <p>{t("reservation.expiryNotice")}</p>
        </Container>

        {isLoading && <Loading />}
      </Container>
    </Container>
  );
};
