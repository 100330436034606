import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../fb";
import useFirestoreCollection from "../../hooks/useFirestoreCollection";
import { useState, useMemo, useEffect, useCallback } from "react";
import { Loading } from "../../Components/Loading";
import { Html5QrcodeScanner } from "html5-qrcode";
import { toast, ToastContainer } from "react-toastify";
import { useDecoder } from "../../hooks/useEncryption";
import "./Scanner.css";

export const Scanner2 = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const addedUsers = useFirestoreCollection(
    "Landau_Prom",
    setIsLoading,
    reloadTrigger
  );
  const decode = useDecoder();

  useEffect(() => {
    const qrScanner = new Html5QrcodeScanner("reader", {
      fps: 10,
      qrbox: {
        width: 250,
        height: 250,
      },
      ghosting: true,
    });

    qrScanner.render(
      (result) => {
        const decodedResult = decode(result);
        setQuery(decodedResult);
      },
      (error) => {}
    );
  }, []);

  const filteredUsers = useMemo(() => {
    const lowercasedQuery = query.toLowerCase().trim();
    return addedUsers.filter(
      (user) =>
        `${user.name} ${user.surname}`
          .toLowerCase()
          .includes(lowercasedQuery) ||
        `${user.surname} ${user.name}`
          .toLowerCase()
          .includes(lowercasedQuery) ||
        user.email.toLowerCase().includes(lowercasedQuery)
    );
  }, [addedUsers, query]);

  const addUserMarked = async (user) => {
    setIsLoading(true);
    try {
      await updateDoc(doc(db, "Landau_Prom", user.email), {
        marked: true,
      });
      toast.success(`${user.name} ${user.surname} is Marked!`);
      quickstart();
    } catch (error) {
      toast.error("Error Marking the User.");
      console.error("Error marking user:", error.message);
    }
    setIsLoading(false);
  };

  const deleteGuest = async (user) => {
    setIsLoading(true);
    try {
      await updateDoc(doc(db, "Landau_Prom", user.email), {
        guest_num: user.guest_num - 1,
      });
      toast.success("Guest deleted!");
      quickstart();
    } catch (error) {
      toast.error("Error deleting guest.");
      console.error("Error deleting guest:", error.message);
    }
    setIsLoading(false);
  };

  const handleReload = useCallback(() => {
    setReloadTrigger((prev) => prev + 1);
  }, []);

  const markedCount = addedUsers.filter((user) => user.marked).length;
  const notMarkedCount = addedUsers.length - markedCount;

  return (
    <section className="users_container">
      <ToastContainer
        position="top-right"
        stacked
        newestOnTop={false}
        autoClose={3000}
      />
      <h1>Landau Prom</h1>
      <div className="reload-search">
        <p style={{ margin: "0.2rem 0" }}>Total: {addedUsers.length}</p>
        <p style={{ color: "rgb(0, 255, 0)", margin: "0.2rem 0" }}>
          Marked: {markedCount}
        </p>
        <p style={{ color: "rgb(255, 0, 0)", margin: "0.2rem 0" }}>
          Not Marked: {notMarkedCount}
        </p>
        <button onClick={handleReload}>Reload</button>
        <div className="search">
          Search:&nbsp;&nbsp;
          <input
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            type="search"
          />
        </div>
      </div>
      <div id="reader"></div>
      <div className="users">
        {filteredUsers.map((user) => (
          <div
            className="user_container"
            key={user.id}
            style={{ background: user.marked ? "blue" : "green" }}
          >
            <p style={{ color: "white" }}>
              {user.name} {user.surname}
              <br />
              {user.email}
            </p>
            <div>
              {user.guest_num > 0 && (
                <button onClick={() => deleteGuest(user)}>
                  Guest ({user.guest_num})
                </button>
              )}
              {user.marked === undefined && (
                <button onClick={() => addUserMarked(user)}>Mark</button>
              )}
            </div>
          </div>
        ))}
      </div>

      {isLoading && <Loading />}
    </section>
  );
};
