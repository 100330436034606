import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../../fb";
import useFirestoreCollection from "../../hooks/useFirestoreCollection";
import { useState, useEffect } from "react";
import { Loading } from "../../Components/Loading";
import { Html5QrcodeScanner } from "html5-qrcode";
import { toast } from "react-toastify";
import "./Scanner.css";
import Container from "../../Components/Container";
import Button from "../../Components/Button";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CancelIcon from "@mui/icons-material/Cancel";
import RepeatOnOutlinedIcon from "@mui/icons-material/RepeatOnOutlined";

export const Scanner3 = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [userState, setUserState] = useState("none");

  useEffect(() => {
    const qrScanner = new Html5QrcodeScanner("reader", {
      fps: 10,
      qrbox: {
        width: 250,
        height: 250,
      },
      ghosting: true,
    });

    qrScanner.render(
      async (result) => {
        userState !== "found" &&
          userState !== "notFound" &&
          setUserState("loading");

        const docRef = doc(db, "DJ_AX_Event", result);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userData = { ...docSnap.data(), id: docSnap.id };
          setUserData(userData);
          setUserState("found");
        } else {
          setUserState("notFound");
        }
      },
      (error) => {}
    );
  }, []);

  const markUser = async (user) => {
    setIsLoading(true);

    try {
      await updateDoc(doc(db, "DJ_AX_Event", user.id), {
        marked: true,
      });
      toast.success(`User is Marked!`);
    } catch (error) {
      toast.error("Error Marking the User.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="users_container">
      <Container center column gap="2rem">
        <div id="reader"></div>

        {userState !== "none" && (
          <Container rounded shadow center bg="white" color="black" pd="1rem">
            {userState === "loading" && <p>Scanning...</p>}
            {userState === "notFound" && (
              <Container column center>
                <CancelIcon color="error" sx={{ fontSize: "5rem" }} />
                <h5 style={{ color: "red" }}>No such QR Code!</h5>
                <Button
                  m="1rem 0 0 0"
                  variant="error"
                  onClick={() => {
                    setUserState("none");
                  }}
                >
                  <h5>Cancel</h5>
                </Button>
              </Container>
            )}
            {userState === "found" && (
              <Container>
                {userData.marked ? (
                  <Container column center>
                    <RepeatOnOutlinedIcon
                      color="error"
                      sx={{ fontSize: "5rem" }}
                    />
                    <h5 style={{ color: "red" }}>QR Code is Used!</h5>
                    <Button
                      m="1rem 0 0 0"
                      variant="error"
                      onClick={() => {
                        setUserState("none");
                      }}
                    >
                      <h5>Cancel</h5>
                    </Button>
                  </Container>
                ) : (
                  <Container gap="1rem" column center>
                    <CheckBoxIcon color="success" sx={{ fontSize: "5rem" }} />
                    <Container gap="1rem">
                      <Button
                        variant="error"
                        onClick={() => {
                          setUserState("none");
                        }}
                      >
                        <h5>Cancel</h5>
                      </Button>

                      <Button
                        variant="success"
                        onClick={() => {
                          markUser(userData);
                          setUserState("none");
                        }}
                      >
                        <h5>Accept</h5>
                      </Button>
                    </Container>
                  </Container>
                )}
              </Container>
            )}
          </Container>
        )}
      </Container>

      {isLoading && <Loading />}
    </section>
  );
};
